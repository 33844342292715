/* Targeting Ant Design's Input component */
.ant-select {
  font-size: 14px; /* default font size for non-mobile devices */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .ant-select {
    font-size: 16px !important; /* font size for mobile devices */
    height: 52px !important;
  }
  .ant-select-selector {
    font-size: 16px !important;
  }
  .ant-select-selection-placeholder {
    font-size: 16px !important;
  }
  .ant-select-selection-search-input {
    font-size: 16px !important;
  }
  .ant-select-selection-search {
    font-size: 16px !important;
  }
  .ant-select-selection-search-mirror {
    font-size: 16px !important;
  }
  .ant-select-selection-search-mirror-content {
    font-size: 16px !important;
  }
  .ant-select-selection-search-mirror-placeholder {
    font-size: 16px !important;
  }
  .ant-select-selection-item {
    font-size: 16px !important;
  }
  .ant-select-item-option-content {
    font-size: 16px !important;
  }
  .ant-select-item {
    font-size: 16px !important;
  }
  .ant-select-item-group {
    font-size: 16px !important;
  }
}

/* Targeting Ant Design's Input component */
.ant-select-single {
  font-size: 14px; /* default font size for non-mobile devices */
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .ant-select-single {
    font-size: 16px; /* font size for mobile devices */
  }
}
