/*LAMA COLORS*/
/*END OF LAMA COLORS*/
.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #131313;
}
.no-scroll {
  overflow: hidden;
  height: 100vh;
  touch-action: none;
  /* Prevents touch interactions */
}
:root {
  --svh: 1svh;
}
